import React from 'react';
import styled, { keyframes } from 'styled-components';
import Text, { H1 } from 'components/atoms/Typography';
import { LinkButton } from 'components/atoms/Button/Button';
import { breakPoints, colors } from 'utils/variables';
import BgShape from 'images/header-aboutus-shapes.inline.svg';
import Container from 'components/atoms/Container/Container';
import Menu from 'components/organisms/Menu/Menu';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const StyledWrapper = styled.div`
  position: relative;
  margin: 0 0 15rem 0;
  opacity: 0;
  animation: ${keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `} 1500ms ease forwards;

  @media (min-width: ${breakPoints.desktop}) {
    margin: 0 0 27.2rem 0;
  }
`;

const StyledContent = styled.div`
  position: relative;
  margin-top: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 15rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 28rem;
  }

  h1 {
    margin-bottom: 40rem;

    @media (min-width: ${breakPoints.tablet}) {
      margin-bottom: 60rem;
    }

    @media (min-width: ${breakPoints.desktop}) {
      margin-bottom: 0;
    }
  }

  .text {
    margin: 1.6rem 0 7rem 0;
    font-size: 1.6rem;
    line-height: 2.5rem;
    letter-spacing: 0;
    text-align: center;

    @media (min-width: ${breakPoints.desktop}) {
      text-align: left;
      max-width: 60rem;
    }
  }

  button {
    margin: auto;
    display: block;

    @media (min-width: ${breakPoints.desktop}) {
      margin: 0;
      animation: ${keyframes`
        0%, 5% {
          transform: rotateZ(0);
        }
        1%, 3% {
          transform: rotateZ(-5deg);
        }
        2%, 4% {
          transform: rotateZ(5deg);
        }
      `} 10s linear infinite;
    }
  }
`;

const StyledImage = styled.div`
  z-index: 10;
  display: block;
  max-height: unset;
  position: absolute;
  padding: 0;
  margin: 0;
  height: 35rem;
  width: 100%;
  top: 5rem;

  @media (min-width: ${breakPoints.tablet}) {
    height: 50rem;
    top: 10rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    z-index: 0;
    height: auto;
    width: 50%;
    top: -10rem;
    transform: translateX(63rem);
    animation: ${keyframes`
      0%, 100% {
        transform: translate(63rem, 1rem);
      }
      50% {
        transform: translate(63rem, -1rem);
      }
    `} 5000ms ease-in-out infinite;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    width: 75rem;
    top: -20rem;
  }
`;

const StyledShape = styled.svg`
  position: absolute;
  display: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: block;
    top: -2rem;
    transform: translateX(32rem);
  }

  @media (min-width: ${breakPoints.desktop}) {
    top: 36rem;
    height: auto;
    width: 40rem;
    transform: translate(88rem, -5rem);
    animation: ${keyframes`
      0%, 100% {
        transform: translate(88rem, -5rem);
      }
      50% {
        transform: translate(88rem, -8rem);
      }
    `} 5000ms ease-in-out infinite;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    width: auto;
    transform: translate(99rem, -7rem);
    animation: ${keyframes`
      0%, 100% {
        transform: translate(99rem, -7rem);
      }
      50% {
        transform: translate(99rem, -10rem);
      }
    `} 5000ms ease-in-out infinite;
  }
`;

const AboutUsHeader = () => {
  const { BgImage } = useStaticQuery(graphql`
    query {
      BgImage: file(relativePath: { regex: "/header-aboutus-image.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Menu />
      <StyledWrapper>
        <StyledImage
          as={Img}
          fluid={BgImage.childImageSharp.fluid}
          alt="header"
          objectFit="contain"
          objectPosition="50% 50%"
          style={{ position: 'absolute', overflow: 'visible' }}
          imgStyle={{ objectFit: 'contain' }}
        />
        <StyledShape as={BgShape} />
        <StyledContent>
          <H1 color={colors.color1}>
            Poznaj <b>nas</b>
          </H1>
          <Text
            html={`<b>Firma Auto-Części KUBECZEK</b> powstała w latach 80-tych. Założycielem firmy był Karol Kubeczek. Od początku działalności zajmował się sprzedażą części do samochodów marki Mercedes. W roku 1995 wraz z synem Adamem rozpoczął działalność na terenie Rybnika.<br/>Przez wszystkie lata działalności, aż po chwilę obecną firma stara się świadczyć usługi na wysokim poziomie.<br/><br/>Wychodząc na przeciw ich oczekiwaniom, począwszy od 2007 r. poszerzyła działalność o <b>“AUTO SERWIS”</b>, w którym dokonuje bieżących napraw samochodowych.`}
          />
          <LinkButton
            to="/kontakt"
            text="Umów wizytę"
            backgroundColor={colors.color4}
            borderColor={colors.color3}
            color={colors.color3}
            backgroundColorHover={colors.color3}
            borderColorHover={colors.color3}
            colorHover={colors.white}
          />
        </StyledContent>
      </StyledWrapper>
    </Container>
  );
};

export default AboutUsHeader;
