import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ThemeShape4 from 'images/themeShapes/themeShape_4.inline.svg';
import { colors, breakPoints } from 'utils/variables';

const StyledCarouselButton = styled.button`
  display: none;
  position: absolute;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: ${colors.color3};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  transform: ${({ prev }) => (prev ? 'rotate(-90deg)' : 'rotate(90deg)')};
  top: 45.8%;
  right: ${({ prev }) => (prev ? 'unset' : '-13rem')};
  left: ${({ prev }) => (prev ? '-13rem' : 'unset')};
  transition: opacity 300ms ease;
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  letter-spacing: 0.18rem;

  @media (min-width: ${breakPoints.desktop}) {
    display: block;
  }

  > svg {
    margin: 0 1.5rem;
    stroke: ${colors.color3};
  }
`;

const getDirectionText = prev => (prev ? 'Poprzednie' : 'Następne');

const CarouselButton = ({ prev, onClick, show }) => (
  <StyledCarouselButton prev={prev} show={show} onClick={onClick}>
    <ThemeShape4 />
    {getDirectionText(prev)}
    <ThemeShape4 />
  </StyledCarouselButton>
);

CarouselButton.propTypes = {
  prev: PropTypes.bool,
  onClick: PropTypes.func,
  show: PropTypes.bool,
};

CarouselButton.defaultProps = {
  prev: false,
  onClick: () => {},
  show: false,
};

export default CarouselButton;
