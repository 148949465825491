import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselButton from 'components/atoms/CarouselButton/CarouselButton';
import { breakPoints } from 'utils/variables';

const StyledWrapper = styled.div`
  position: relative;
  width: calc(100% + 2rem);
  margin-bottom: 10rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 0;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
  }
`;

const StyledImage = styled.div`
  position: relative;
  border-radius: 0.3rem;
  width: 30rem !important;
  height: 23rem !important;

  @media (min-width: ${breakPoints.tablet}) {
    width: 35rem !important;
    height: 27rem !important;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 38.4rem !important;
    height: 29.7rem !important;
  }

  img {
    object-fit: contain !important;
  }
`;

const getImages = (image, index) => <StyledImage key={index} as={Img} fluid={image.childImageSharp.fluid} />;

const ImagesCarousel = ({ images }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <StyledWrapper>
      <CarouselButton prev show={currentSlide > 0} onClick={() => sliderRef.current.slickPrev()} />
      <Slider
        ref={sliderRef}
        speed={500}
        slidesToShow={3}
        slidesToScroll={1}
        arrows={false}
        infinite={false}
        afterChange={current => setCurrentSlide(current)}
        responsive={[
          {
            breakpoint: 1300,
            settings: {
              autoplay: true,
              infinite: true,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              infinite: true,
              slidesToShow: 1,
            },
          },
        ]}
      >
        {images.map(getImages)}
      </Slider>
      <CarouselButton show={currentSlide < images.length - 3} onClick={() => sliderRef.current.slickNext()} />
    </StyledWrapper>
  );
};

ImagesCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  ).isRequired,
};

export default ImagesCarousel;
