import React from 'react';
import Layout from 'templates/Layout/Layout';
import Container from 'components/atoms/Container/Container';
import Footer from 'components/organisms/Footer/Footer';
import AboutUsHeader from 'components/organisms/AboutUsHeader/AboutUsHeader';
import OurPriorities from 'components/organisms/OurPriorities/OurPriorities';
import ShortShop from 'components/organisms/ShortShop/ShortShop';
import Workshop from 'components/organisms/Workshop/Workshop';

const Page = () => (
  <Layout
    metaTitle="Auto serwis Kubeczek to rodzinna firma ze sporym bagażem doświadczenia"
    metaDescription="Serwis samochodowy z wieloletnim doświadczeniem. Uczestniczymy w licznych szkoleniach w celu podwyższania naszych kwalifikacji."
  >
    <AboutUsHeader />
    <Container>
      <OurPriorities />
      <Workshop />
      <ShortShop withThemeShapes={false} />
    </Container>
    <Footer />
  </Layout>
);

export default Page;
