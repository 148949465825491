import React from 'react';
import styled from 'styled-components';
import { H2 } from 'components/atoms/Typography';
import ImagesCarousel from 'components/molecules/ImagesCarousel/ImagesCarousel';
import { graphql, useStaticQuery } from 'gatsby';
import ThemeShape3 from 'images/themeShapes/themeShape_3.inline.svg';
import { breakPoints } from 'utils/variables';

const StyledWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 0 -10rem 0;

  @media (min-width: ${breakPoints.desktop}) {
    margin: 22rem 0 -3.5rem 0;
  }

  > svg {
    margin-bottom: 1.6rem;
  }

  > h2 {
    margin-bottom: 2rem;

    @media (min-width: ${breakPoints.tablet}) {
      margin-bottom: 4rem;
    }

    @media (min-width: ${breakPoints.desktop}) {
      margin-bottom: 6.7rem;
    }
  }
`;

const Workshop = () => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativePath: { regex: "/workshop/" } }) {
        nodes {
          childImageSharp {
            fluid(quality: 100, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <ThemeShape3 />
      <H2>
        Nasz <b>warsztat</b>
      </H2>
      <ImagesCarousel images={images.nodes} />
    </StyledWrapper>
  );
};

export default Workshop;
